<template>
  <ams-select-table
    :ams_api_path="ams_api_path"
    :ams_type="ams_type"
    :ams_fields="ams_fields"
    :pre_selected_items="pre_selection"
    :select_mode="mode_selection"
    @selectedRows="selectRows"
  />
</template>

<script>
import AmsSelectTable from './AmsSelectTable.vue'

export default {
  components: {
    AmsSelectTable
  },
  props: {
    pre_selection: {
      Type: Array
    }
  },
  data() {
    return {
      mode_selection: 'single',
      ams_type: 'brands',
      ams_api_path: 'api/admin/v1',
      ams_fields: [
       { 
          key: 'attributes.name',
          label: 'Name',
          sortable: true,
          filterable: true,
          class: 'text-left',
          sortDirection: 'asc' 
        }
      ]
    }
  },
  methods: {
    selectRows(items) {
      this.$emit('selectedItems', items)
    }
  }
}
</script>