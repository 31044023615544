<template>
  <b-container fluid>
    <!-- User Interface controls -->
    <b-button v-b-toggle.collapse-1 align="left" squared variant="outline-secondary" size="sm">Find...</b-button>
    <b-collapse id="collapse-1" class="mt-2">
    <b-card bg-variant="light">
    <b-row>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Sort"
          label-for="sort-by-select"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
          v-slot="{ ariaDescribedby }"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sort-by-select"
              v-model="sortBy"
              :options="sortOptions"
              :aria-describedby="ariaDescribedby"
              class="w-75"
            >
              <template #first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>

            <b-form-select
              v-model="sortDesc"
              :disabled="!sortBy"
              :aria-describedby="ariaDescribedby"
              size="sm"
              class="w-25"
            >
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Initial sort"
          label-for="initial-sort-select"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="initial-sort-select"
            v-model="sortDirection"
            :options="['asc', 'desc', 'last']"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Filter"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          v-model="sortDirection"
          label="Filter On"
          description="Leave all unchecked to filter on all data"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
            v-model="filterOn"
            :aria-describedby="ariaDescribedby"
            class="mt-1"
          >
            <b-form-checkbox 
              v-for="item in filterOptions" 
              :key="item.value"
              :value="item.value"
            >
              {{item.text}}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>
    </b-row>
    </b-card>
    </b-collapse>
    <b-row>
      <b-col sm="7" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      stacked="md"
      show-empty
      small
      :select-mode="select_mode"
      responsive="sm"
      ref="selectableTable"
      selectable
      @row-selected="onRowSelected"
      @filtered="onFiltered"
    >

    </b-table>
  </b-container>
</template>

<script>
import {http_v1} from '@/axios'

export default {
  props: {
    ams_type: {
      Type: String
    },
    ams_api_path: {
      Type: String
    },
    ams_fields: {
      Type: Array
    },
    select_mode: {
      Type: String
    },
    pre_selected_items: {
      Type: Array
    }
  },
  data() {
    return {
      link: this.ams_type + "/create",
      api_route: this.ams_api_path.replace(/-/g, '_') + '/' + this.ams_type.replace(/-/g, '_') + '/',
      included: [],
      items: [],
      fields: [
        ...this.ams_fields,
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
    filterOptions() {
      return this.fields
        .filter(f => f.filterable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  },
  mounted() {
    this.callApi()
  },
  methods: {
    callApi () {
      http_v1.get (
        this.api_route, {
          params: {
            page: this.currentPage,
          }
        }
      ).then(response => {
        if(response.status == 200) {
          this.totalRows = response.headers.total
          this.perPage = response.headers['per-page']
          this.items = response.data.data
          this.included = response.data.included
        }
        else {
          window.console.log(response.status)
        }
      }).catch(error => {
        this.items = []
        this.totalRows = 0
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          window.console.log(message)
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
    onRowSelected(items) {
      this.$emit('selectedRows', items)
    },
    onFiltered(filteredItems) {
      // Set Filter params and call API, resetting page to beginning
      window.console.log(filteredItems)
      this.currentPage = 1
      this.callApi()
    }
  }
}
</script>
